import React from 'react'
import { Link } from 'react-router-dom'

const PaymentFailure = () => {
  return (
    <>
    <div>PaymentFailure</div>
    <Link to="/">Click here to go to home</Link>
    </>
  )
}

export default PaymentFailure